import { Link } from "gatsby"
import React from "react"

import { Layout } from "../components/Layout"

export default function Error() {
  return (
    <Layout lang="fr">
      <main
        className="min-h-screen bg-cover bg-top sm:bg-top max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 xl:rounded-lg"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-black uppercase tracking-wide">
            Erreur 404
          </p>
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
            Uh oh ! Je pense que vous êtes perdu.
          </h1>
          <p className="mt-2 text-lg font-medium text-black">
            Il semble qu’il n’y ait aucune vague par là.
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
            >
              Retourner à l'accueil
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  )
}
